/*  Table of Contents
1. Global Styles
2. Typography
3. Layout
4. Components
*/

/*  =====================
    1. Global Styles 
    =====================
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$bg-color-primary: #25262A;
$highlight-color-main: #70F3A5;
$highlight-color-hover: #94f1ba;
$bg-overlay: #2D2E32;
$bg-glass: #2D2E32;
$bg-grey: #2D2E32;
$bg-dark: #2D2E32;

$text-color: rgb(255, 255, 255);

* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: $text-color;
    scroll-behavior: smooth;
}
body { 
    height: 100vh;
    background-color: $bg-color-primary;  
    margin: 0;
}

ul{
    margin: 0;
    padding: 0;
    li{
        list-style-type: none;
    }
}

a{
    text-decoration: none;
    color: $text-color;
}
section{
    padding: 4rem 0;
    height: 100%;
    overflow: hidden
}

.social-media-container{
    ul{
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: center;
        li{
            margin: 0 0.8rem;
            text-align: center;
        }
    }
    span{
        font-size: 0.75rem;
    }
    i{
        transition: color 0.5s, transform 0.5s;
        font-size: 2rem;
        &:hover{
            color: $highlight-color-main;
            //transform: scale(1.05);
            
        }
    }
    
}

/*  =========================
    2. Typography 
    h1,h2,a,p
    =========================
*/
h1,h2,h3,h4,h5,h6{
    font-weight: 200;
}
.section-title{
    font-weight: 600;
}
.bold{
    font-weight: bold;
}
.projecti-title-container{
    display: flex;
    justify-content: space-between;
}
.project-title{
    margin-top: 0;
    color: $highlight-color-main;
    font-weight: 400;
    span{
        font-weight: 200;
    }
}
.project-category{
    margin-top: 0;
    font-weight: 200;
    span{
        font-size: 1rem;
    }
}
.project-url a{
    font-weight: 600;
    transition: color 0.3s;
    &:hover{
        color: $highlight-color-main;
    }
}
.project-desc{
    font-size: .8rem;
    text-align: justify;
}
/*  ========================
    3. Layout 
    header, footer
    ========================
*/
.container{
    margin: auto 10vw;
}

header{
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 0;
    padding: 1vw 10vw 0 10vw;
    position: fixed;
    z-index: 10;
    width: 100%;
    background: black;
    background: -moz-linear-gradient(180deg, black 33%, transparent 100%);
    background: -webkit-linear-gradient(180deg, black 33%, transparent 100%);
    background: linear-gradient(180deg, black 33%, transparent 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000918",endColorstr="#ffffff",GradientType=1);

    margin-left: -10vw;
    margin-right: -10vw;
    height: 7vh;
}
nav{
    ul{
        padding: 0;
        margin: 0;
        display: flex;
        li{
            padding: 0;
            margin: 0px 20px;
        }
    }
}
.nav-hero{
    background: url('../img/bg/hero-bg-v2-1400.jpg') $bg-overlay;
    background-blend-mode: multiply;
    padding: 0 10vw 0.5vw 10vw;
    height: 60vh;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    background-origin: border-box;
}
.hero{
    position: absolute;
    top: 30%;
}

.my-skills{
    //background: url('../img/bg/regular-bg.jpg') $bg-overlay;
    background-blend-mode: multiply;
    padding: 3rem 10vw;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
    background-origin: border-box;

    margin-left: -10vw;
    margin-right: -10vw;
}

.description{
    text-align: justify;
}

.contact-me{
    //background-color: $bg-grey;
    text-align: center;
    margin-left: -10vw;
    margin-right: -10vw;
    background-color: $bg-dark;
}

.about-me{
    background-color: $bg-grey;
    min-height: 300px;
    position: relative;
    .description{
        margin-right: 320px;
        font-size: .9rem;
        max-width: 900px;
    }
}


/*  =====================
    4. Components 
    buttons, cards
    =====================
*/
.btn{
    display: inline-block;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid $highlight-color-main;
    border-radius: 25px;
    padding: 1rem 2rem;
    color: $text-color;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    transition: transform 0.4s,background-color 0.4s,color 0.4s,;
    &:hover{
        background-color: $highlight-color-hover;
        transform: scale(1.05);
        color: black;
    }
}

.profile-pic.large{
    max-width: 220px;
    border-radius: 50%;
    border: 5px solid $highlight-color-main;
    
    position: absolute;
    right: calc(12vw - 5px);
    top: 50%;
    transform: rotate(10deg) translateY(-50%);
}   

.divider{
    border-bottom: 1px solid $highlight-color-main;
    margin: 0;
    width: 20%;
    float: none;
    display: inline-block;
    clear: both;
    &.clear{
        opacity: 0;
    }
}

.card-container{
    display: flex;
    justify-content: space-between;
    transform: translateX(0px);

    .card{
        background-color: $bg-glass;
        padding: 1rem 1rem;
        border-radius: 35px;
        text-align: center;
        width: 350px;
        min-height: 650px;
        backdrop-filter: blur(15px);
        .card-title{
            .card-title-text{
                font-weight: 600;
                //white-space: nowrap;
            }
            i{
                font-size: 2.5rem;
                margin: 1rem;
            }
        }
        .card-items{
            li{
                margin: 1rem auto;
            }
        }
    }
    

}

.projects, .about-me{
    margin-left: -10vw;
    margin-right: -10vw;
    padding: 3rem 10vw;
}


.item-selector-header{
    button{
        background-color: rgba(255, 255, 255, 0);
        outline: none;
        border: 0.5px solid rgba(255, 255, 255, 0);
        border-radius: 5px;
        padding: 1rem 2.5rem;
        vertical-align: center;
        margin: auto .5rem;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
        white-space: nowrap;
        //color: rgba(224, 224, 224, 0.33);

        &.active{
            border-color: $highlight-color-main;
            color: $text-color;
        }
        &:hover{
            background-color: $bg-glass;
        }
    }
}


.project{
    //border-bottom: 0.5px solid rgba(224, 224, 224, 0.33);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    //remove from the screen 
    opacity: 0;
    left:100vw;
    max-height: 0;
    padding-bottom: 0;
    padding-top: 0;

    transition: 
    left 0.5s ease-in-out,
    max-height 0.5s ease-in-out,
    padding-bottom 0.5s ease-in-out,
    padding-top 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
    // apply border to first element and remove from the last 
    .project-text{
        max-width: 50%;
    }
    .project-images{
        position: relative;
        max-width: 515px;
        margin: 0 auto;
        img{
            display: none;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            max-width: 100%;
            border-radius: 10px;
            border: 7px solid $bg-grey;
            max-height: 350px;
            margin: auto;

            &:hover{
                //opacity: 0.65;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.6);
            }
            &.active{
                display: block;
            }
        }
    }
    &.active{
       left: 0;
       height: auto;
       opacity: 1;
       max-height: 1000px;
       padding-bottom: 2.5rem;
       padding-top: 2.5rem;
    }
}
.technologies-used{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .technology{
        margin: .5rem;
        text-align: center;
        line-height: 1px;
        i{
            font-size: 1.8rem;
            width: 30px;
            height: 30px;
        }
        p{
            font-size: 0.8rem;
        }
        svg{
            width: 30px;
            height: 30px;
            fill: $text-color;
        }
        transition: transform 0.4s; 
        &:hover{
            transform: scale(1.1);
        }
    }
}
.project-links{
    margin: 0 auto;
    text-align: center;
}
.link{
    margin: 0;
    border: 1px solid grey;
    font-weight: bold;
    font-size: .9rem;
    padding: .5rem 2.5rem;
    border-radius: 5px ;
    transition: background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,color 0.3s ease-in-out;

    i{
        transition: color 0.3s ease-in-out
    }
    &:hover{
        background-color: $highlight-color-main;
        border-color: $highlight-color-main;
        color: $bg-dark;
        i{
            color: $bg-dark;
        }
    }
    &.disabled{
        background-color: rgb(71, 69, 69);
        color: rgb(136, 136, 136);
        pointer-events: none;
        i{
            color: rgb(136, 136, 136);
        }
    }

}

.nav-link{
    transition: border-bottom 0.5s, transform 0.5s;
    padding: 0 0 0.5rem 0;
    border-bottom: 3px solid rgba(255, 255, 255, 0);
    display: block;
    white-space: nowrap;

    &.active{
        border-bottom: 3px solid $highlight-color-main;
    }

    &:hover{
        border-bottom: 3px solid #fff;
    }

}
.sidebar-container{
    position: absolute
}

.logo{
    margin: 0.2rem 0;
    font-size: 1em;
    display: block;
    span{
        font-weight: bold;
    }
}

#sidebar-btn{
    transform: translateX(30px);
    margin: 0.3rem 0;
    padding: 1rem;
    height: .2rem;
    background-color: $highlight-color-main;
    display: none;
    i{
        transform: translateY(-11px);
        color: $bg-dark;
    }
}

#sidebar-close{
    margin: 0 auto;
    margin-bottom: 2rem;
    float: left;
    padding: 1rem;
    height: .2rem;
    background-color: $highlight-color-main;
    display: block;
    i{
        transform: translateY(-11px);
        color: $bg-grey;
    }
}




.sidebar-container{
    transition: transform 0.5s ease-in-out;
    width: 180px;
    z-index: 15;
    position: absolute;

    background-color: $bg-dark;
    padding: 1rem 2rem;
    right: -180px;
    top: 0;
    height: 100vh;
    text-align: right;
    -webkit-box-shadow: 0px 2px 11px 0px rgba(0,0,0,0.4); 
    box-shadow: 0px 2px 11px 0px rgba(0,0,0,0.4);

    .nav-link{
        display: inline-block;
        margin: 1.5rem 0;
        font-size: 1rem;
    }

    &.active{
        transform: translateX(-180px);
    }
}




.carousel-btn{
    display: none;
    text-align: center;
    background-color: $highlight-color-main;
    color: $bg-dark;
    outline: none;
    border: none;
    position: absolute;
    top: 45%;
    z-index: 5;
    padding: 0.6rem;
    &.prev{
        left: 0;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        -webkit-box-shadow: 8px 1px 10px -1px rgba(0,0,0,0.35); 
        box-shadow: 8px 1px 10px -1px rgba(0,0,0,0.35);
    }
    &.next{
        right: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        -webkit-box-shadow: -8px 1px 10px -1px rgba(0,0,0,0.35); 
        box-shadow: -8px 1px 10px -1px rgba(0,0,0,0.35);
    }
    font-size: 2.5rem;
}

.overlay{
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 20vw;
    
    .carousel-btn{
        cursor: pointer;
        top: 45%;
        font-size: 2vw;
        display: block;
        background-color: black;
        border-radius: 1px;
        -webkit-box-shadow: -8px 1px 10px -1px rgba(0,0,0,0.0); 
        box-shadow: -8px 1px 10px -1px rgba(0,0,0,0.0);
        &:hover{
            background-color: $highlight-color-main;
        }
        &.expand{
            font-size: 1.5rem;
            top: 90%;
            right: 0;
        }
    }
}
 
#modal-pic-number{
   text-align: center;
   margin: 1rem;
}


.modal{
    display: none;
    &.active{
        display: block;
    }
    position: fixed;
    z-index:  10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.90);
    //padding-top: 1.5rem;


    .picture-feed{
        margin-top: 3rem;
        display: flex;
        justify-content: center;
    }

    img{
        object-fit: cover;
        padding: 0.5rem;

        max-height: 85vh;
        display: none;
        &:hover{
            opacity: 1 !important;
        }
        &.active{
            display: block;
        }
        max-width: 90vw;
    }

    .modal-header{
        .close-btn{
            border: none;
            padding: .5rem 1rem;
            width: auto;
            right: 5%;
            top: 2%;
            position: absolute;
            i{
                color: white;
            }
        }
    }

    .modal-btn{
        background-color: rgba(255, 255, 255, 0);
        color: white;
        border: 1px solid  white;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        font-size: 1.5rem;
        padding: .25rem 1rem;
        &:hover{
            color: $highlight-color-hover;
            border-color: $highlight-color-hover;
            i{
                color: $highlight-color-hover;
            }
        }
    }

    .modal-footer{
        display: flex;
        justify-content: center;
        // position: absolute;
        // bottom: 10px;
        // left: 50vw;
    }
    

}


// Handle Responsive Cards 
@media only screen and (max-width: 900px) {

    .item-selector-header{
        display: flex;
        flex-direction: column;
        button{
            width: 50vw;
            margin: .5rem auto;
            padding: .5rem 2rem;
            font-size: 0.8rem;
        }
    }

    .project{
        display: block;
        .project-text{
            max-width: 100%;
        }
        .project-images{
            margin-top: 2.5rem;
            img{
                max-width: 100%;
            }
        }
    }

    header{
        height: 10vh;
    }

    .my-skills{
        padding: 3rem 10vw;
    }

    .carousel-btn{
        display: block;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        outline: none;
        &:hover{
            opacity: 0.8;
        }
    }
    .carousel{
        position: relative;
        width: 300px !important;
        margin: 0 auto;
        padding: 0;
        overflow: hidden;
        
    }
    .card-container{
        justify-content: start;
        transform: translateX(0);
        transition: transform 0.5s ease-in-out;
        z-index: 1;
        
        .card{
            min-width: 300px;
            display: block;
            margin: 0;
            padding: 0.5rem 2rem;
        }
    }

}

// Handle Responsive Navbar and About me Section 
@media only screen and (max-width: 800px) {
    .technologies-used{
        display: flex;
        flex-wrap: wrap;
    }
    .nav-hero{
        height: 90vh;
    }

    .link{
        padding: 0.5rem 1.5rem;
    }

    #sidebar-btn{
        display: block;
    }
    nav{
        ul{
            display: none;
        }
    }
    .about-me-container{
        .description{
            margin-right: auto;
        }
        .profile-pic{
            position: static;
            transform: rotate(10deg);
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }


    
}

// Handle Phone Sizes
@media only screen and (max-width: 420px) {
    .nav-hero{
        height: 100vh;
    }

    .overlay .carousel-btn{
        top: 35%;
    }

    .logo{
        position: absolute;
        top: 15px;
    }

    header{
        height: 9vh;
    }

    .profile-pic.large{
        border: 3px solid $highlight-color-main;
    }
    .hero{
        h2{
            font-size: 1.3rem;
        }
    }

    .modal img{
        height: 100%;
        width: 100vw;
    }


}

// Handle Margin between Project Pics 
/* No greater than 1400px, no less than 400px */
@media (max-width:1400px) and (min-width:900px) {
    .project-images{
        img{
            width: 85%;
        }
    }
}​
    

.spacer{
    width: 15%;
}

